
import { Component, Prop, Vue } from 'vue-property-decorator'
import { QuotaArray } from '@/pages/project/created/quota/quota-generate-interface';
import { PROJECT_STATUS } from '@/enum/enums';

@Component
export default class SamplingRows extends Vue {
  @Prop() surveyIdProp!: string;
  @Prop() quotaId!: string;
  @Prop() quotaSubId!: string;
  @Prop() disabled!: boolean;
  @Prop() auto!: boolean;
  @Prop() labels!: string;
  @Prop() wanted!: number;
  @Prop() feasible!: number;
  @Prop() complete!: number;
  @Prop() progress!: number;
  @Prop() remain!: number;
  @Prop() invitationConfirm!: boolean;
  @Prop() quotaArray!: QuotaArray[];
  @Prop() nextRunAt?: string;
  @Prop() nextHour?: number;
  @Prop() status!: PROJECT_STATUS;
  private pendingAuto = false;
  private reload = false;
  private forceSendCollapse = false;
  private forceNumber = 0;
  private historyCollapse = false;
  private historyQueue: Array<{ queueId: string, count: number, regDate: string, estimateDate: string, sent: boolean, sentDate?: string }> = [];
  private historyQueueBtn: string[] = [];
  private interval: ReturnType<typeof setInterval> | null = null;
  private autoMessage = '';
  private autoFlag = false;
  private renderLabels = '';
  private disabledModel = false;
  created(): void {
    this.renderLabels = this.labels;
    this.disabledModel = this.disabled;
    // this.interval = setInterval(() => {
    //   if (this.fireSize > 1.3) {
    //     this.fireDirection = -0.1;
    //   } else if(this.fireSize < 0.5) {
    //     this.fireDirection = 0.1;
    //   }
    //   this.fireSize += this.fireDirection;
    // }, 500);
  }

  beforeDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  async setDisable(flag: boolean): Promise<void> {
    const url = `/sampling/disable/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    const { result, data, message } = await this.axios({
      url,
      method: 'POST'
    });
    if (result) {
      await this.refreshSub();
      if (message) this.successMessage(message.join('\r\n'));
      this.disabledModel = !this.disabledModel;
    } else {
      if (message) this.errorMessage(message.join('\r\n'));
      this.disabledModel = !flag;
    }
  }

  async setAutoMessage(): Promise<void> {
    this.pendingAuto = true;
    const url = `/sampling/auto-sampling/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    const { data, message, result } = await this.axios({
      url,
      method: 'GET'
    });
    if (result) {
      const { startDate, endDate } = data as { startDate: string, endDate: string };
      if (message && message.length) {
        const confirmMessage = message.join('\r\n');
        this.autoFlag = true;
        this.autoMessage = confirmMessage;
      } else {
        await this.setAuto();
      }
    } else {
      if (message) {
        this.validationMessages(message);
      }
    }
    this.pendingAuto = false;
  }

  async setAuto(): Promise<void> {
    const url = `/sampling/auto-sampling/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    const { data, message, result } = await this.axios({
      url,
      method: 'POST'
    });
    if (result) await this.refreshSub();
  }

  async refreshSub(): Promise<void> {
    this.reload = true;
    this.clear();
    const url = `/sampling/feasible/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    const { data } = await this.axios({
      url,
      method: 'GET',
    });
    const { quota } = data;
    this.$emit('refresh', this.quotaId, quota);
    await new Promise((resolve) => setTimeout(resolve, 300));
    this.reload = false;
    this.disabledModel = false;
    this.forceNumber = 0;
  }

  private clear(): void {
    this.forceSendCollapse = false;
    this.forceNumber = 0;
    this.historyCollapse = false;
    this.historyQueue = [];
    this.historyQueueBtn = [];
  }

  async wantedChange(value: string): Promise<void> {
    return;
    // if (isNaN(+value)) {
    //   console.log('invalid number');
    // } else {
    //   // this.wanted = +value;
    //   const url = `/sampling/wanted/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    //   const { data, result, message } = await this.axios({
    //     url,
    //     method: 'PATCH',
    //     data: {
    //       wanted: +value,
    //     }
    //   });
    //   if (!result) {
    //     for (const msg of message || []) {
    //       if (typeof msg === 'string') {
    //         this.errorMessage(msg);
    //       } else {
    //         const { message: [m] } = msg;
    //         this.errorMessage(m);
    //       }
    //     }
    //   } else {
    //     if (message) this.successMessage(message.join('\r\n'));
    //   }
    //   await this.refreshSub();
    // }
  }

  async forceSend(): Promise<void> {
    this.disabledModel = true;
    const url = `/sampling/create/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    const { data, result, message } = await this.axios({
      url,
      method: 'POST',
      data: {
        wanted: this.forceNumber,
      }
    });
    if (result) {
      const { count } = data as { count: number };
      if (message) this.successMessage(message.join('\r\n'));
      await this.refreshSub();
      return;
    }
    if (message) this.errorMessage(message.join('\r\n'));
    this.disabledModel = false;
  }

  async history(): Promise<void> {
    if (this.historyCollapse) {
      this.historyCollapse = false;
      return;
    }
    const url = `/sampling/history-specific/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    const { data, result } = await this.axios({
      url,
      method: 'GET'
    });
    if (result) {
      const { queue } = data;
      this.historyQueue = queue;
    }
    this.historyCollapse = result || false;
  }

  async cancelQueue(queueId: string): Promise<void> {
    this.historyQueueBtn.push(queueId);
    const url = `/sampling/queue/${this.surveyIdProp}/${this.quotaId}/${this.quotaSubId}`;
    const { data, result, message } = await this.axios({
      url,
      method: 'DELETE',
      data: {
        queueId
      }
    });
    await new Promise((resolve) => setTimeout(resolve, 300));
    const index = this.historyQueueBtn.findIndex(id => id === queueId);
    if (index > -1) this.historyQueueBtn.splice(index, 1);
    await this.refreshSub();
  }

  showNextDate() {
    if (this.nextHour !== undefined && this.nextDate) this.$notify({
      group: 'message',
      title: `Next`,
      text: `It will be sent after ${this.nextHour} hour.(${this.nextDate}) `,
      type: 'success',
    });
  }

  get remainFormat(): string {
    return this.remain.digit();
  }

  get feasibleFormat(): string {
    return this.feasible ? this.feasible.digit() : '0';
  }

  get isDisabled(): boolean {
    return this.disabled || !this.invitationConfirm;
  }

  get rowsLabels() {
    const gender = this.quotaArray.find(({ type }) => type === 'GENDER');
    const region = this.quotaArray.find(({ type }) => type.startsWith('REGION'));
    if (gender) {
      const { value } = gender;
      const isMale = value.indexOf('MALE');
      const isFeMale = value.indexOf('FEMALE');
      if (isMale >= 0 && this.renderLabels.indexOf('🧏‍♂') === -1) {
        this.renderLabels = `🧏‍♂️${this.renderLabels}`;
      }
      if (isFeMale >= 0 && this.renderLabels.indexOf('🧏‍♀') === -1) {
        this.renderLabels = `🧏‍♀️${this.renderLabels}`;
      }
    }
    if (region) {
      const { value } = region;
    }
    return this.renderLabels;
  }

  get trBg() {
    if (this.remain <= 0) return 'text-primary font-weight-bold';
    if (this.auto) return 'text-danger font-weight-bold';
    return '';
  }

  get nextDate() {
    if (this.nextRunAt) return new Date(this.nextRunAt).toLocaleString();
    return null;
  }

  private get isSendAvailable () {
    return this.status === PROJECT_STATUS.ON;
  }

}

