
import { Vue, Prop, Component } from 'vue-property-decorator';
import { Quota, QuotaSub } from '@/interface/interface';
import { PROJECT_STATUS } from '@/enum/enums';
import SamplingRows from '@/pages/project/created/sampling/rows.vue';
@Component({
  components: {
    SamplingRows
  }
})
export default class SamplingQuota extends Vue {
  @Prop() idx!: number;
  @Prop() quotaId!: string;
  @Prop() label!: string;
  @Prop() sub!: QuotaSub[];
  @Prop() invitationConfirm!: boolean;
  @Prop() testFlag!: boolean;
  @Prop() status!: PROJECT_STATUS;
  private subLocal: QuotaSub[] = [];
  private autoAllFlag = false;
  private disableBtn = false;
  private isAutoAll = false;
  private autoExists = false;
  private autoMessage: string | null = null;
  private forceSendAllFlag = false;
  private forceSendCount = '0';

  created(): void {
    this.subLocal = this.sub;
    this.loadAfter();
    console.warn(this.status);
  }

  refresh(quotaId: string, data: QuotaSub): void {
    const { _id: quotaSubId, auto } = data;
    const quotaSubIndex = this.subLocal.findIndex(({ _id }) => _id === quotaSubId);
    this.$emit('refresh', { quotaId, index: quotaSubIndex, data });
    if (quotaSubIndex > -1) this.subLocal.splice(quotaSubIndex, 1, data);
    this.isAutoAll = this.subLocal.some(({ auto }) => auto);
  }

  async setAutoMessage(): Promise<void> {
    const url = `/sampling/auto-sampling/${this.surveyId}/${this.quotaId}`;
    const { data, message, result } = await this.axios({
      url,
      method: 'GET'
    });
    console.log(data, message);
    if (result) {
      const { startDate, endDate } = data as { startDate: string, endDate: string };
      if (message && message.length) {
        const confirmMessage = message.join('\r\n');
        this.autoAllFlag = true;
        this.autoMessage = confirmMessage;
      } else {
        await this.setAutoAll();
      }
    } else {
      if (message) {
        this.validationMessages(message);
      }
    }
  }

  private async load() {
    const url = `/sampling/feasible/${ this.surveyId }/${this.quotaId}`;
    const { result, data, message } = await this.axios({ url });
    if (result && data) {
      const { quota } = data as { quota: QuotaSub[] };
      this.subLocal = quota;
      this.loadAfter();
    }
  }

  private async setAutoAll() {
    this.proc(true);
    const allStop = this.isAutoAll;

    const url = `/sampling/auto-sampling/${this.surveyId}/${this.quotaId}`;
    const params: { allStop: boolean } = {
      allStop
    };
    const { data, message, result } = await this.axios({
      url,
      method: 'POST',
      data: params,
    });
    if (result) {
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      await this.load();
    }
    this.autoMessage = null;
    this.autoAllFlag = false;
    this.proc(false);
  }

  loadAfter() {
    // this.isAutoAll = this.subLocal.every(item => item.auto);
    this.isAutoAll = this.subLocal.some(({ auto }) => auto);
  }

  async forceSendAll(): Promise<void> {
    const url = `/sampling/create/${this.surveyId}/${this.quotaId}`
    const { data, message, result } = await this.axios({
      url,
      data: {
        wanted: this.forceSendCount
      },
      method: 'POST',
    });
    this.forceSendCount = `0`;
    this.forceSendAllFlag = false;
  }

  sendTest() {
    this.$emit('sendTest');
  }

  forceCreateCollection() {
    this.$emit('forceCreateCollection');
  }

  get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  get forceSendCountCheck() {
    return isNaN(+this.forceSendCount) ? false : +this.forceSendCount > 0
  }

  private get isSendAvailable () {
    return this.status === PROJECT_STATUS.ON;
  }

  sum(sub: QuotaSub[], field: 'wanted' | 'complete' | 'remain' | 'feasible'): string {
    const total = sub.reduce((prev, cur) => {
      return prev + cur[field];
    }, 0);
    return total.digit();
  }

  avg(sub: QuotaSub[], field: 'progress'): string {
    const total = sub.reduce((prev, cur) => {
      return prev + cur[field];
    }, 0);
    const avg = +(total / sub.length).toFixed(1);
    return avg.digit();
  }
}
