
import { Component, Emit, Vue } from 'vue-property-decorator';
import CreatedNav from "@/pages/project/created/created-nav.vue";
import SamplingQuota from '@/pages/project/created/sampling/sampling-quota.vue';
import SamplingRows from '@/pages/project/created/sampling/rows.vue';
import ProjectMap from '@/pages/project/components/project-map.vue';
import { DemoGraphicInterface, Quota, QuotaSub, QuotaArray } from '@/interface/interface';
import { mixins } from 'vue-class-component'
import { CreatedMixin } from '@/mixins/created-mixin'
import RegistrationStep from "@/pages/project/components/registration-step.vue";
import { PROJECT_STATUS } from '@/enum/enums';

interface HistoryQueue {
  count: number;
  createAt: string;
  creatorId: string;
  estimateDate: string;
  processing?: boolean;
  processingDate?: string;
  queueId: string;
  quotaId: string;
  quotaSubId: string;
  regDate: string;
  sent: boolean;
  sentDate?: string;
  suppliers: {[key: string]: number;}
  surveyId: string;
  surveyNo: number;
  _id: string;
}
interface HistoryQueueSet {
  isNext: boolean;
  _id: string;
  queue: HistoryQueue[];
}
@Component({
  components: {
    RegistrationStep,
    CreatedNav,
    SamplingQuota,
    SamplingRows,
    ProjectMap,
  }
})
export default class CreatedSampling extends mixins(Vue, CreatedMixin) {
  private quotaRows: Quota[] = [];
  private demoGraphic: DemoGraphicInterface | null = null;
  private invitationConfirm = true;
  private confirmMessage = '';
  private confirmFlag = false;
  private testFlag = false;
  private invitationClose = false;
  private historylastId: string | null = null;
  private history: HistoryQueueSet | null = null;
  private historyFlag = false;
  private genderLabel: { [key: string]: string; } = {};
  private regionLabel: { [key: string]: string; } = {};

  async created() {
    this.loading = true;
    await this.mixinsLoad();
    await this.info();
    await this.load();
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    this.loading = false;
  }

  async info() {
    const { data } = await this.axios.get<{ demoGraphic: DemoGraphicInterface, invitationConfirm: boolean; genderLabel: { [key: string]: string; }; regionLabel: { [key: string]: string; }; }>(`/sampling/info/${this.surveyId}`);
    const { demoGraphic, invitationConfirm, genderLabel, regionLabel } = data;
    this.demoGraphic = demoGraphic;
    this.invitationConfirm = invitationConfirm;
    this.invitationClose = this.invitationConfirm;
    this.genderLabel = genderLabel;
    this.regionLabel = regionLabel;
  }

  private async historyRecent(next?: boolean) {
    if (this.historyFlag && !next) {
      this.historyFlag = false;
      this.history = null;
      this.historylastId = null;
      return;
    }
    this.historyFlag = true;
    const url = this.historylastId ? `/sampling/history/${this.surveyId}/${this.historylastId}` : `/sampling/history/${this.surveyId}`;
    const { data, result, message } = await this.axios({
      url, method: 'GET'
    });
    if (result && message && message[0]) {
      this.successMessage(message[0] as string);
    }
    if (result && data) {
      if (this.history) {
        const { isNext, queue, _id } = data as HistoryQueueSet;
        this.history.isNext = isNext;
        this.history._id = _id;
        this.history.queue.push(...queue);
      }
       else {
         this.history = data;
      }
      if (this.history && this.history.isNext) this.historylastId = this.history._id;
    }
  }

  private invitationConfirmFocus() {
    if (this.invitationConfirm) return false;
  }

  private checkQuotaAuto(quota: Quota[]) {
    for (const q of quota) {
      const { _id, sub } = q;
    }
  }

  async load(cb?: ReturnType<typeof Function>): Promise<void> {
    try {
      this.query = Object.entries(this.$route.query).map(([k,v])=>`${k}=${v}`).join('&')
      const { result, data, message } = await this.axios({
        url: `/sampling/feasible/${ this.surveyId }`
      });
      if (result) {
        const { quota } = data as { quota: Quota[] };
        this.quotaRows = quota;
        this.checkQuotaAuto(quota);
        if (cb) cb(quota);
      }
    } catch (e) {
      console.log(e)
    }
  }

  async initialInvitation(): Promise<void> {
    if (!this.invitationClose) {
      this.invitationClose = true;
      return;
    }
    const url = `/sampling/initial-invitation/${this.surveyId}`;
    const { result, data, message } = await this.axios({
      url,
      method: 'GET'
    });
    if (message) {
      this.confirmFlag = true;
      this.confirmMessage = message.join('\r\n');
      // const check = confirm(confirmMessage);
      // if (check) await this.initialInvitationConfirm();
    }
  }

  async initialInvitationConfirm(): Promise<void> {
    this.proc(true);
    const url = `/sampling/initial-invitation/${this.surveyId}`;
    const { data, message, result } = await this.axios({
      url,
      method: 'POST'
    });
    if (result) {
      const { count, invitationConfirm } = data as { count: number, invitationConfirm: boolean };
      this.invitationConfirm = invitationConfirm;
    } else if (message) {
      this.errorMessage(message.join('\r\n'));
    }
    this.proc(false);
  }

  async forceCreateCollection(): Promise<void> {
    this.proc(true);
    const url = `/sampling/create-collection/${this.surveyId}`;
    const { result, message, data } = await this.axios({
      url, method: 'POST',
    });
    console.log(result, message, data);
    this.proc(false);
  }

  async sendTest(): Promise<void> {
    if (!this.testFlag) {
      this.testFlag = true;
      return;
    }
    this.proc(true);
    const url = `/sampling/send-test/${this.surveyId}`;
    const { result, message, data } = await this.axios({
      url, method: 'POST',
    });
    console.log(data);
    if (result) {
      this.validationMessages(message, true);
    }
    this.proc(false);
    this.testFlag = false;
  }

  refresh({ quotaId, index, data }: { quotaId: string, index: number, data: QuotaSub[] }) {
    console.log(quotaId, index, data);
  }

  get demoLabels(): { gender: string, age: string, region: string } {
    if (this.demoGraphic === null) {
      return { gender: '', age: '', region: '' };
    }
    const { gender, minAge, maxAge, region } = this.demoGraphic;
    return {
      gender: gender.map(k => this.genderLabel[k]).join(','),
      age: `${minAge} ~ ${maxAge}`,
      region: region.map(k => this.regionLabel[k]).join(',')
    }
  }

  sum(sub: QuotaSub[], field: 'wanted' | 'complete' | 'remain' | 'feasible'): string {
    const total = sub.reduce((prev, cur) => {
      return prev + cur[field];
    }, 0);
    return total.digit();
  }

  avg(sub: QuotaSub[], field: 'progress'): string {
    const total = sub.reduce((prev, cur) => {
      return prev + cur[field];
    }, 0);
    const avg = +(total / sub.length).toFixed(1);
    return avg.digit();
  }
  get quotaMakeUrl(): string {
    return `/project/created/quota/${this.surveyId}`
  }

  get disableBtn(): boolean {
    return !this.invitationConfirm;
  }

  get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  private changeStatus(status: PROJECT_STATUS) {
    this.status = status;
  }
}
